import React from 'react';
import Banner from '../components/WebDesignDevBanner';
import WebDesginDivSec from '../components/WebDesginDivSec';
import WebDesginDevsection from '../components/WebDesginDevsection';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import BrandsCarousel from '../components/BrandsCarousel';
import WebsitePackages from './WebsitePackages';


const WebDesignDev = () => {
    return (
      <div>
        <Banner />
        <WebDesginDivSec/>
        <WebDesginDevsection/>
        <TestimonialsCarousel/>
        <BrandsCarousel/>
        <WebsitePackages />
  
      </div>
    );
  };
  
  export default WebDesignDev;