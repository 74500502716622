import React from 'react';
import Banner from '../components/DigitalMarketingBanner';
import DigitalMarketingSection from '../components/DigitalMarketingSection'
import WhyChooseUs  from '../components/WebDesginDevsection'
import Services from '../components/DigitalMarketingServices'
import WhyPartnerWithUs from '../components/DigitalMarketingWhyPartnerWithUs'
import TestimonialsCarousel from '../components/TestimonialsCarousel'
import FormSection from '../components/DigitalMarketingFormSection'
import SocialMediaPackages from './SocialMediaPackages';
import SEOPackages from './SEOPackages';



const DigitalMarketing = () => {
    return (
      <div>
        <Banner />
        <DigitalMarketingSection/>
        <WhyChooseUs />
        <Services/>
        <WhyPartnerWithUs/>
        <TestimonialsCarousel />
        <SocialMediaPackages />
        <SEOPackages />
        <FormSection />


      </div>
    );
  };
  
  export default DigitalMarketing;