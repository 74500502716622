import React, { useState } from 'react';
import TabComponent from './TabComponent';
import CardComponent from './CardComponent';
import { Arch01, Bakery01, ClothingP1, Cloths02, Disablity, Electrical, Finance, Handyman, Healthcare, HomeRevovation1, jewelry01, Limo, Pestcontrol, Supplement, Therapy, Transportation1 } from '../Assets';

const projects = [
  {
    title: 'Clothing Shop',
    category: 'Shopify',
    imageSrc: ClothingP1,
    link: 'https://westernwhimsey.com/',
  },
  {
    title: 'Transportation',
    category: 'PhP',
    imageSrc: Transportation1,
    link: 'https://www.skyvans.com.au/',
  },
  {
    title: 'Home Renovations',
    category: 'WordPress',
    imageSrc: HomeRevovation1,
    link: 'https://nextlevelprojectsqld.com.au/',
  },
  {
    title: 'Body Supplements',
    category: 'Shopify',
    imageSrc: Supplement,
    link: 'https://tropheenutrition.com/',
  },
  {
    title: 'Clothing Shop',
    category: 'Shopify',
    imageSrc: Cloths02,
    link: 'https://elmari.com.au/',
  },
  {
    title: 'Jewelry Shop',
    category: 'Shopify',
    imageSrc: jewelry01,
    link: 'https://www.susanrose.com.au/',
  },
  {
    title: 'Architect',
    category: 'WordPress',
    imageSrc: Arch01,
    link: 'https://atriux.com.au/',
  },
  {
    title: 'Electrical Service',
    category: 'WordPress',
    imageSrc: Electrical,
    link: 'https://24hrpower.net.au/',
  },
  {
    title: 'Bakery Shop',
    category: 'WordPress',
    imageSrc: Bakery01,
    link: 'https://www.lobethalbakery.com.au/',
  },
  {
    title: 'Finance',
    category: 'WordPress',
    imageSrc: Finance,
    link: 'https://www.fiducian.com.au/',
  },
  {
    title: 'Pest Control',
    category: 'WordPress',
    imageSrc: Pestcontrol,
    link: 'https://competitivepestcontrol.com.au/',
  },
  {
    title: 'Massage Therapy',
    category: 'WordPress',
    imageSrc: Therapy,
    link: 'https://knotsbegonemassage.com.au/',
  },
  {
    title: 'Handyman',
    category: 'WordPress',
    imageSrc: Handyman,
    link: 'https://tmplumbinganddrainage.com.au/',
  },
  {
    title: 'Luxury Limo Services',
    category: 'WordPress',
    imageSrc: Limo,
    link: 'https://marqueelimousines.com/',
  },
  {
    title: 'Health Care',
    category: 'WordPress',
    imageSrc: Healthcare,
    link: 'https://totallysmiles.com.au/',
  },
  {
    title: 'Disability Services',
    category: 'WordPress',
    imageSrc: Disablity,
    link: 'https://www.melbournedisabilityservices.com.au/',
  },
  // Add more projects for other categories...
];

const categories = ['WordPress', 'Shopify','PhP'];

const GalleryComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const filteredProjects = projects.filter(
    (project) => project.category === selectedCategory
  );

  return (
    <div className="bg-[#0f101d] py-16  mx-auto">
      <TabComponent
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
       <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {filteredProjects.map((project, index) => (
          <CardComponent
            key={index}
            title={project.title}
            category={project.category}
            imageSrc={project.imageSrc}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
};

export default GalleryComponent;
