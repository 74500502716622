import React from 'react';
import Logo from '../Assets/ZoneService.png';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FiMapPin, FiPhone, FiMail } from 'react-icons/fi'; // Importing icons from react-IconsS
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#0e192f] text-white pt-16 pb-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Logo and Description */}
        <div className="flex flex-col items-start ">
          <Link to="/">
          <img src={Logo} alt="Zone Services Logo" className="mb-4 h-36" />
          </Link>
          <p className="text-gray-400">
            We tailor solutions to your business goals and audience, boosting your online sales and leads.
          </p>
          
        </div>

        {/* Quick Links */}
        <div>
          <h4 className="text-xl font-bold mb-4">QUICK LINKS</h4>
          <ul className="space-y-2">
            <li><Link to="/" className="text-gray-400 hover:text-yellow-500">Home</Link></li>
            <li><Link to="/About" className="text-gray-400 hover:text-yellow-500">About us</Link></li>
            <li><Link to="/portfolio" className="text-gray-400 hover:text-yellow-500">Portfolio</Link></li>
            <li><Link to="/Pricing" className="text-gray-400 hover:text-yellow-500">Pricing</Link></li>
            <li><Link to="/Contact" className="text-gray-400 hover:text-yellow-500">Contact</Link></li>
          </ul>
        </div>

        {/* Services */}
        <div>
          <h4 className="text-xl font-bold mb-4">SERVICES</h4>
          <ul className="space-y-2">
            <li><Link to="/web-design-development" className="text-gray-400 hover:text-yellow-500">Web Design & Development</Link></li>
            <li><Link to="/digital-marketing" className="text-gray-400 hover:text-yellow-500">Social Marketing Service</Link></li>
            <li><Link to="/website-maintenance" className="text-gray-400 hover:text-yellow-500">Website Maintenance</Link></li>
            <li><Link to="/logo-design" className="text-gray-400 hover:text-yellow-500">Logo Design</Link></li>
            <li><Link to="/web-hosting" className="text-gray-400 hover:text-yellow-500">Web Hosting</Link></li>
          </ul>
        </div>

        {/* Contact Us */}
        <ul className="space-y-2 text-gray-400">
          <li className="flex items-center">
            <FiMapPin className="h-5 w-5 mr-2 text-white" /> {/* Location Icon */}
            Second Ave Marsden QLD 4132
          </li>
          <li className="flex items-center">
            <FiPhone className="h-5 w-5 mr-2 text-white" /> {/* Phone Icon */}
            <a href='tel:0483 964 322'>0483 964 322</a>
          </li>
          <li className="flex items-center">
            <FiMail className="h-5 w-5 mr-2 text-white" /> {/* Email Icon */}
            <a href="mailto:zoe@zoneservices.com.au;">zoe@zoneservices.com.au</a>
          </li>
        </ul>

      </div>
      <div className="border-t border-gray-700 mt-8 pt-4 text-center text-gray-400 text-sm">
        © Copyrights 2024. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
