import React from 'react';

const LogoPackages = () => {
  const packages = [
    {
      name: 'BASIC PACKAGE',
      price: '$100 only',
      features: [
        '3 Creative Logo Design Concepts',
        '7 Revisions',
        '1 Dedicated Designer',
        'File Format (JPG)',
        '100% Satisfaction Guarantee',
        'Standard Delivery 24 Hours'
      ]
    },
    {
      name: 'ECONOMY PACKAGE',
      price: '$150 only',
      features: [
        '6 Creative Logo Design Concepts',
        '12 Revisions',
        '2 Dedicated Designers',
        '100% Unique Design Guarantee',
        'Standard Delivery 24 Hours',
        'All File Formats (AI, PSD, EPS, PNG, GIF, JPG, PDF)',
        '100% Ownership Rights'
      ]
    },
    {
      name: 'STARTUP PACKAGE',
      price: '$250 Only',
      features: [
        'Unlimited Creative Logo Design Concepts',
        'Unlimited Revisions',
        'Business Stationery Included',
        'Email Signature Included',
        'MS Word Letterhead Included',
        'All File Formats (AI, PSD, EPS, PNG, GIF, JPG, PDF)',
        '4 Dedicated Designers',
        'Standard Delivery 24 Hours',
        '100% Ownership Rights',
        'Business Card Design Included',
        '100% Satisfaction Guarantee',
        '100% Unique Design Guarantee'
      ]
    }
  ];

  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-wrap justify-center">
        
      {packages.map((pkg, index) => (
        <div key={index} className="w-full md:w-1/3 p-4">
          <div className="bg-gray-900 text-white rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h2 className="text-yellow-400 text-lg">LOGO</h2>
            <h1 className="text-2xl font-bold">{pkg.name}</h1>
            <p className="text-yellow-400">GET IT NOW IN</p>
            <h3 className="text-3xl font-bold">{pkg.price}</h3>
            <ul className="text-left mt-4 mb-4">
              {pkg.features.map((feature, idx) => (
                <li key={idx} className="text-sm mb-2 flex items-center">
                  <svg className="w-4 h-4 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <a href="mailto:zoe@zoneservices.com.au"
               className="mt-4 inline-block bg-yellow-500 text-white font-semibold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-300">
              Get It Now
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LogoPackages;
