import React from 'react';
import Banner from './LogoDesignBanner';
import About from './LogoDesignAbout';
import Section from './LogoDesignSec';
import Service from './LogoDesignServices';
import TestimonialsCarousel from './TestimonialsCarousel';
import FormSection from './DigitalMarketingFormSection';
import LogoPackages from './LogoPackages';




const LogoDesign = () => {
    return (
      <div>
        <Banner />
        <About />
        <Section />
        <Service />
        <TestimonialsCarousel />
        <FormSection />
        {/* Main Section Title and Subtitle */}
<div className="text-center my-3">
  <h2 className="text-4xl font-bold text-gray-900">Our Logo Packages</h2>
  <p className="text-xl text-gray-700 mt-2">Choose a package that best fits your needs.</p>
</div>

        <LogoPackages />



      </div>
    );
  };
  
  export default LogoDesign;