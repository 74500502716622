import React from 'react';

const SocialMediaPackages = () => {
  const packages = [
    {
      duration: '3 MONTHS STARTUP',
      price: '$450 only',
      platforms: 'Any 2 Social Media platforms: Facebook, Instagram, LinkedIn, Tiktok, Youtube.',
      features: [
        '3-5 Unique Posts per week',
        '1 Engagement Video per week',
        'Professional Content Writing for Each Post',
        'Hashtags Research',
        'Personalized Marketing Strategy (Overview)',
        'Likes, Followers, and Engagement Campaign',
        'Monthly Progress report',
        'Ad Campaign Management',
        'Spam Monitoring & Management',
        'Query and Comments Reply',
        'Business Page Optimization to rank your page',
        'Weekly and Monthly Report',
        '100% Satisfaction Guarantee',
        '100% Results Guarantee'
      ]
    },
    {
      duration: '6 MONTHS STARTUP',
      price: '$800 only',
      platforms: 'All Social Media platforms: Facebook, Instagram, LinkedIn, Tiktok, Youtube.',
      features: [
        '5-6 Unique Posts per week',
        '1 Engagement Video per week',
        'Professional Content Writing for Each Post',
        'Hashtags Research',
        'Personalized Marketing Strategy (Overview)',
        'Likes, Followers, and Engagement Campaign',
        'Monthly Progress report',
        'Ad Campaign Management',
        'Spam Monitoring & Management',
        'Query and Comments Reply',
        'Business Page Optimization to rank your page',
        'Short Clip Commercial Videos',
        'Copywriting & Visual Designs',
        'Weekly and Monthly Report',
        '100% Satisfaction Guarantee',
        '100% Results Guarantee'
      ]
    }
  ];

  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center my-10">
        <h2 className="text-4xl font-bold text-gray-900">Our Social Media Marketing Packages</h2>
        <p className="text-xl text-gray-700 mt-2">Customized solutions to enhance your digital presence.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {packages.map((pkg, index) => (
          <div key={index} className="bg-gray-900 text-white rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h2 className="text-yellow-400 text-lg">SOCIAL MEDIA MARKETING PACKAGES</h2>
            <h1 className="text-2xl font-bold">{pkg.duration}</h1>
            <p className="text-yellow-400">{pkg.platforms}</p>
            <h3 className="text-3xl font-bold">{pkg.price}</h3>
            <ul className="text-left mt-4 mb-4">
              {pkg.features.map((feature, idx) => (
                <li key={idx} className="text-sm mb-2 flex items-center">
                  <svg className="w-4 h-4 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <a href="mailto:zoe@zoneservices.com.au"
               className="mt-4 inline-block bg-yellow-500 text-white font-semibold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-300">
              Get It Now
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaPackages;
