import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/ZoneServices.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const hoverTimeoutRef = useRef(null);

  const toggleServicesDropdown = (isOpen) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    if (isOpen) {
      setIsServicesDropdownOpen(true);
    } else {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsServicesDropdownOpen(false);
      }, 70); // Dealing with delayed hiding of the dropdown
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu open/close
  };

  return (
    <>
      {/* Top Bar */}
      <div className="w-full bg-transparent flex justify-between items-center p-2 shadow-md">
        {/* Contact Information */}
        <div className="flex items-center space-x-6 text-gray-700 text-sm">
          <div className="flex items-center space-x-2">
            <span className="text-yellow-500">
              <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5" />
            </span>
            <span>zoe@zoneservices.com.au</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-yellow-500">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="h-5 w-5" />
            </span>
            <span>Second Ave Marsden QLD 4132</span>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <header className="w-full top-0 left-0 z-50 bg-transparent flex justify-between items-center p-4 border-t-2 border-black">
        <div className="flex items-center">
          <Link to="/">
            <img src={Logo} alt="Logo" className="h-12 md:h-24 rounded-full" />
          </Link>
        </div>

        <nav className="relative">
          {/* Desktop Navigation */}
          <ul className="hidden md:flex justify-between space-x-6 text-blue-800 font-sans font-medium text-md">
            <li><Link to="/About" className="hover:underline">About</Link></li>
            <li
              className="relative"
              onMouseEnter={() => toggleServicesDropdown(true)}
              onMouseLeave={() => toggleServicesDropdown(false)}
            >
              <button className="hover:underline flex items-center">
                Services <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
              </button>
              {isServicesDropdownOpen && (
                <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-20">
                  <li><Link to="/web-design-development" className="block px-4 py-2 hover:bg-gray-100">Web Design Development</Link></li>
                  <li><Link to="/digital-marketing" className="block px-4 py-2 hover:bg-gray-100">Digital Marketing</Link></li>
                  <li><Link to="/website-maintenance" className="block px-4 py-2 hover:bg-gray-100">Website Maintenance</Link></li>
                  <li><Link to="/logo-design" className="block px-4 py-2 hover:bg-gray-100">Logo Design</Link></li>
                  <li><Link to="/web-hosting" className="block px-4 py-2 hover:bg-gray-100">Web Hosting</Link></li>
                </ul>
              )}
            </li>
            <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
            <li><Link to="/Contact" className="hover:underline">Contact Us</Link></li>
          </ul>

          {/* Mobile Menu Toggle */}
          <button className="md:hidden" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={faBars} className="text-blue-800" />
          </button>

          {/* Mobile Navigation */}
          {isMobileMenuOpen && (
            <ul className="absolute top-full left-0 right-0 bg-white shadow-md py-2 z-50 flex flex-col items-center w-full">
              <li><Link to="/About" className="block px-4 py-2 text-center text-blue-800 hover:bg-gray-100">About</Link></li>
              <li><Link to="/Services" className="block px-4 py-2 text-center text-blue-800 hover:bg-gray-100">Services</Link></li>
              <li><Link to="/Portfolio" className="block px-4 py-2 text-center text-blue-800 hover:bg-gray-100">Portfolio</Link></li>
              <li><Link to="/Contact" className="block px-4 py-2 text-center text-blue-800 hover:bg-gray-100">Contact Us</Link></li>
            </ul>
          )}
        </nav>

        <div className="hidden md:flex items-center space-x-4">
  <a href="tel:0483 964 322"
     className="font-medium font-sans text-gray-900  p-2 rounded-md bg-yellow-600 glow-on-hover">
    0483 964 322
  </a>
</div>

      </header>
    </>
  );
};

export default Header;
