import React from 'react';

const SEOPackages = () => {
  const packages = [
    {
      title: 'SEO Proposal',
      price: '$700 for 15 Keywords',
      duration: '6 Months Plan',
      features: [
        'Pages Optimized (10 page)',
        '15 Selected Keywords Targeting',
        'Keyword Research',
        'Keyword Grouping',
        'Keyword Mapping',
        'On-page Optimization',
        'SEO Road Map',
        'Blog Page Creation',
        'Webpage Copywriting (3 pages, 350 words per page)',
        'Title Tag Optimization (10 titles)',
        'Meta Description Optimization (10 descriptions)',
        'Meta Keywords Optimization (10 keywords)',
        'xml Sitemap Optimization',
        'Broken Link Report',
        'Rich Snippet Recommendations',
        'Breadcrumbs',
        'INITIAL OFF-PAGE SEO',
        'Social Bookmarking',
        'Slide Share Marketing',
        'Forums/FAQ’s',
        'Link Building',
        'Directory Submission',
        'Local Business Listings',
        'Back Linking (up to 10 links)'
      ]
    },
    {
      title: 'SEO Proposal',
      price: '$1000 for 20 Keywords',
      duration: '6 Months Plan',
      features: [
        'Website Audit',
        'Pages Optimized (10 page)',
        '20 Selected Keywords Targeting',
        'Keyword Research',
        'Keyword Grouping',
        'Keyword Mapping',
        'On-page Optimization',
        'SEO Road Map',
        'Blog Page Creation',
        'Webpage Copywriting (3 pages, 350 words per page)',
        'Title Tag Optimization (10 titles)',
        'Meta Description Optimization (10 descriptions)',
        'Meta Keywords Optimization (10 keywords)',
        'xml Sitemap Optimization',
        'Broken Link Report',
        'Rich Snippet Recommendations',
        'Breadcrumbs',
        'INITIAL OFF-PAGE SEO',
        'Social Bookmarking',
        'Slide Share Marketing',
        'Forums/FAQ’s',
        'Link Building',
        'Directory Submission',
        'Local Business Listings',
        'Back Linking (up to 10 links)',
        'Phase 1: Fix 146 Errors, 1907 Warnings, and 7761 Notices'
      ]
    }
  ];

  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center my-10">
        <h2 className="text-4xl font-bold text-gray-900">Our SEO Packages</h2>
        <p className="text-xl text-gray-700 mt-2">Optimize your digital presence for the top of Google.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {packages.map((pkg, index) => (
          <div key={index} className="bg-gray-900 text-white rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h1 className="text-2xl font-bold">{pkg.title}</h1>
            <h2 className="text-yellow-400 text-lg">{pkg.duration}</h2>
            <h3 className="text-3xl font-bold">{pkg.price}</h3>
            <ul className="text-left mt-4 mb-4">
              {pkg.features.map((feature, idx) => (
                <li key={idx} className="text-sm mb-2 flex items-center">
                  <svg className="w-4 h-4 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <a href="mailto:zoe@zoneservices.com.au"
               className="mt-4 inline-block bg-yellow-500 text-white font-semibold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-300">
              Get It Now
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SEOPackages;
