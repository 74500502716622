import React from 'react';
import Banner from '../Assets/Banner.jpg';

const Mainbanner = () => {
  return (
    <div
      className="relative bg-cover bg-top bg-no-repeat pt-16 md:pt-24 lg:pt-40 -mt-16 md:-mt-40 -z-50" // Adjusted padding-top and negative margin-top
      style={{ backgroundImage: `url(${Banner})`, minHeight: '75vh' }} // Set minimum height to ensure coverage
    >
      <div className="text-center px-4 sm:px-6 lg:px-8"> 
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-900">
          AUSTRALIA'S BEST WEB DESIGN AGENCY
        </h1>
        <p className="mt-2 sm:mt-4 md:mt-6 text-sm sm:text-base md:text-lg leading-6 text-gray-600">
          Empower Your Business With Zone Services's Advanced Web Solutions
        </p>

        <div className="mt-8 md:mt-12 lg:mt-96"> 
          <a
            href="#start-your-project"
            className="inline-block bg-yellow-500 text-white font-semibold py-3 px-6 rounded-full shadow-md hover:bg-yellow-600"
          >
            Start Your Project
          </a>
        </div>
      </div>
    </div>
  );
};

export default Mainbanner;
