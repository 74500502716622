import React, { useState } from 'react';

const CardComponent = ({ imageSrc, title, category, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="m-2">
      <div
        className="relative bg-transparent rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 ease-in-out"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundSize: 'cover',
          backgroundPosition: isHovered ? 'center bottom' : 'center top',
          transition: 'background-position 3s ease-in-out',
          height: '320px', // Adjust height as needed
        }}
      >
        <div className="absolute bottom-0 left-0 right-0 p-4 text-center bg-gradient-to-t from-[#0f101d] to-transparent">
          <h3 className="text-xl font-bold text-white mb-1">{title}</h3>
          <p className="text-sm text-gray-400">{category}</p>
        </div>
      </div>
      <div className="px-6 pt-4 pb-2">
        <a href={link} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          View Project
        </a>
      </div>
    </div>
  );
};

export default CardComponent;
