import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPageCard = ({ image, title, platform, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative bg-transparent rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 ease-in-out"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: isHovered ? 'center bottom' : 'center top', // This changes the position on hover
        transition: 'background-position 5s ease-in-out', // Smooth transition for background position
        height: '320px', // Fixed height for the card
      }}
    >
      <div className="absolute bottom-0 left-0 right-0 p-4 text-center bg-gradient-to-t from-[#0f101d] to-transparent">
        <h3 className="text-xl font-bold text-white mb-1">{title}</h3>
        <p className="text-sm text-gray-400">{platform}</p>
        <Link className='text-white' to={link}>Visit Website</Link>
      </div>
    </div>
  );
};

export default LandingPageCard;
