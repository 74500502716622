import React from 'react';

const WebsitePackages = () => {
  const packages = [
    {
      name: 'BASIC WEBSITE PACKAGE',
      price: '$450 Only',
      features: [
        '1 - 5 Page Website',
        '10 Stock Images',
        '7 Custom Made Banner Designs',
        'Mobile Responsive',
        'FREE Google Friendly Sitemap',
        'Unlimited Revisions',
        '2 jQuery Slider Banner',
        'Search Bar',
        'Complete W3C Certified HTML',
        '24 to 48 hours TAT',
        '100% Ownership Rights',
        'Facebook & Twitter Page Design',
        'Online Payment Integration (Optional)',
        'Google Indexing',
        '100% Satisfaction Guarantee'
      ]
    },
    {
      name: 'PROFESSIONAL WEB PACKAGE',
      price: '$699 Only',
      features: [
        '5 - 10 Page Website',
        'Stock Photos',
        '15 Banner Design',
        '5 jQuery Slider Banner',
        'Custom Content Management System (CMS)',
        'FREE Google Friendly Sitemap Complete',
        'W3C Certified HTML',
        'Domain Name Registration',
        'Hosting for 1 year',
        'Domain for 1 year',
        '100% Ownership Rights',
        '24 to 48 hours TAT',
        'Social Media Plugins Integration',
        'Twitter & Facebook Page Design',
        'Online Payment Integration (Optional)',
        'Google Indexing',
        'SEO Content (15 to 20 keywords)',
        '100% Unique Design Guarantee',
        '100% Money Back Guarantee'
      ]
    },
    {
      name: 'ELITE WEB PACKAGE',
      price: '$1,100 Only',
      features: [
        'Unlimited Page Website',
        '25 Banner Designs',
        '20 jQuery Slider Banner',
        'Stock Photos',
        'Website Hosting for 2 years',
        'Domain for 2 years',
        'FREE Google Friendly Sitemap Complete W3C Certified',
        'HTML24 to 48 hours TAT',
        'Website Maintenance for 2 years',
        'Instagram, Facebook, & Twitter Page Design',
        'Free Google Indexing',
        'Online Payment Integration (Optional)',
        'SEO Content (15 to 20 keywords)',
        'Mobile Responsive Site',
        'Complete Branding Solution',
        'Domain Name Registration',
        'Social Media Plugins Integration',
        '100% Satisfaction Guarantee',
        '100% Unique Design Guarantee',
        '100% Ownership Rights'
      ]
    }
  ];

  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center my-10">
        <h2 className="text-4xl font-bold text-gray-900">Our Website Packages</h2>
        <p className="text-xl text-gray-700 mt-2">Explore our web design solutions tailored for your needs.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {packages.map((pkg, index) => (
          <div key={index} className="bg-gray-900 text-white rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h2 className="text-yellow-400 text-lg">WEB</h2>
            <h1 className="text-2xl font-bold">{pkg.name}</h1>
            <p className="text-yellow-400">GET IT NOW IN</p>
            <h3 className="text-3xl font-bold">{pkg.price}</h3>
            <ul className="text-left mt-4 mb-4">
              {pkg.features.map((feature, idx) => (
                <li key={idx} className="text-sm mb-2 flex items-center">
                  <svg className="w-4 h-4 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <a href="mailto:zoe@zoneservices.com.au"
               className="mt-4 inline-block bg-yellow-500 text-white font-semibold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-300">
              Get It Now
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebsitePackages;
