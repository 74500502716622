import React, { useState } from 'react';
import ComputerMockup from '../Assets/Computer-Mockup.png';
import axios from "axios";

const FreeQuoteForm = () => {


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
   
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, formData);
      alert("Message sent successfully");
      setFormData({ name: "", email: "", phone: ""});
    } catch (error) {
      console.error("Error sending email", error);
      alert("Failed to send message");
    }
  };
  return (
    <div className="relative bg-[#0F101D] text-white py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
          <h2 className="text-4xl font-bold mb-4">Get A Free Quote</h2>
          <p className="text-lg mb-6">Let's Spark a Conversation!</p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="name">Enter Your Name <span className="text-red-500">*</span></label>
                <input type="text"  value={formData.name}
                onChange={handleChange} id="name" placeholder="Full Name" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-red-500">*</span></label>
                <input type="email"  value={formData.email}
                onChange={handleChange} id="email" placeholder="Add Email" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="phone">Phone no <span className="text-red-500">*</span></label>
              <input type="text"  value={formData.phone}
                onChange={handleChange} id="phone" placeholder="Phone No" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
            </div>
            <button type="submit" className="w-full p-3 bg-yellow-500 text-black font-semibold rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50">Send Message</button>
          </form>
        </div>
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <img src={ComputerMockup} alt="Laptop and Mobile Mockup" className="w-3/4 lg:w-2/3" />
        </div>
      </div>
    </div>
  );
};

export default FreeQuoteForm;
